import { useTranslation } from 'react-i18next';
import { HiOutlineCash, HiOutlineCurrencyDollar } from 'react-icons/hi';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { addEditSupporterTypes, updateSupporterById } from 'api/supporter/supporter';
import classNames from 'classnames';
import CustomIcon from 'components/common/CustomIcon';
import TextAreaInput from 'components/input/textInput/textAreaInput';
import { Checkbox, Label } from 'flowbite-react';
import { AddressType, ContactType, SupporterType } from 'utils/proptypes';
import { concatName, convertNumberToCurrency, getArrayFromArray, getNumberValueFromApi, messageErrors } from 'utils/utils';

type SupporterInfoProps = { selectedSupporter: SupporterType; refetchSupporterApi: Function };

const SupporterInfo = (props: SupporterInfoProps) => {
  const { selectedSupporter, refetchSupporterApi } = props;
  const [t] = useTranslation();

  const handleUpdateSupporter = async (supporterPayload: addEditSupporterTypes) => {
    await updateSupporterById<addEditSupporterTypes>(selectedSupporter?.id as string, supporterPayload);
  };

  const mutation = useMutation('create-update-supporter', {
    mutationFn: handleUpdateSupporter,
  });

  const editSupporterClick = (payload: { beneficiaries?: string; wCCNIsBeneficiary?: boolean }) => {
    const successCallback = (message: string) => {
      toast.success(message);
      refetchSupporterApi();
    };

    mutation.mutate(
      {
        ...(payload as any),
      },
      {
        onSuccess: () => {
          const message: string = t('supporterManagerPage.editSuccessMessage');
          successCallback(message);
        },
        onError: async (error: any) => {
          const message: string = messageErrors(error, t);
          toast.error(message);
        },
      },
    );
  };

  return (
    <div className="container-left__info h-auto flex flex-col justify-start items-center py-8 bg-white rounded-sm mb-4">
      <div className="w-full flex flex-col justify-center items-center space-y-1 px-4">
        <h3 className="w-full leading-none text-gray-900 font-semibold text-xl text-center" style={{ overflowWrap: 'break-word' }}>
          {concatName({
            firstName: selectedSupporter?.firstName ? selectedSupporter?.firstName : '',
            lastName: selectedSupporter?.lastName ? selectedSupporter?.lastName : '',
          })}
        </h3>
      </div>
      <div className="w-full flex justify-center items-start py-8 space-x-8">
        <div className="flex flex-col justify-center items-center space-y-3">
          <CustomIcon Icon={HiOutlineCurrencyDollar} bgColor="bg-purple-200" textColor="text-purple-600" />
          <p className="leading-none text-gray-900 text-xs font-normal">{t('totalBorrowed')}</p>
          <h3 className="leading-none text-gray-900 text-sm font-semibold">
            {selectedSupporter?.supporterTotalAmount?.totalBorrowed
              ? convertNumberToCurrency(getNumberValueFromApi(selectedSupporter?.supporterTotalAmount?.totalBorrowed) as number)
              : ''}
          </h3>
        </div>
        <div className="flex flex-col justify-center items-center space-y-3">
          <CustomIcon Icon={HiOutlineCash} bgColor="bg-green-200" textColor="text-green-600" />
          <p className="leading-none text-gray-900 text-xs font-normal">{t('totalOutStanding')}</p>
          <h3 className="leading-none text-gray-900 text-sm font-semibold">
            {selectedSupporter?.supporterTotalAmount?.totalOutstanding
              ? convertNumberToCurrency(
                  (getNumberValueFromApi(selectedSupporter?.supporterTotalAmount?.totalOutstanding) as number) < 1
                    ? Math.round(getNumberValueFromApi(selectedSupporter?.supporterTotalAmount?.totalOutstanding) as number)
                    : (getNumberValueFromApi(selectedSupporter?.supporterTotalAmount?.totalOutstanding) as number),
                )
              : ''}
          </h3>
        </div>
      </div>
      <div className="w-full border-b border-gray-200" />
      <div className="h-auto flex flex-col justify-start items-start py-6 space-y-4 px-4 w-full">
        <h3 className="w-full text-gray-500 text-base font-normal text-start uppercase">{t('address')}</h3>
        {selectedSupporter?.contacts?.length > 0
          ? getArrayFromArray<ContactType, AddressType>({
              items: selectedSupporter?.contacts,
              argFilter: 'address',
            })?.map((address, index) => (
              <p key={`address-${index}`} className="w-full text-gray-900 text-base font-normal" style={{ overflowWrap: 'break-word' }}>
                {`
                  ${address.street ? address.street + ',' : ''} ${address.city ? address.city + ',' : ''} ${
                  address.state ? address.state + ',' : ''
                } ${address.zip ? address.zip + ',' : ''} 
                  ${address.country?.name ? address.country?.name : ''}`}
              </p>
            ))
          : ''}
      </div>
      <div className="w-full border-b border-gray-200" />
      <div className="h-auto flex flex-col justify-center items-start pt-6 px-4 w-full">
        <TextAreaInput
          id="beneficiaries"
          label={t('beneficiaries')}
          defaultValue={selectedSupporter?.beneficiaries ? selectedSupporter?.beneficiaries : ''}
          className="rounded-md bg-white border border-gray-200"
          rowTotal={3}
          isOtherBgLabel={true}
          changeValueHandler={editSupporterClick}
        />
        <i className="text-gray-400 font-normal leading-none text-xs pb-4 pt-2">{t('pressToBreakLine')}</i>
        <div className="flex justify-center items-center space-x-2">
          <Checkbox
            id="wCCNIsBeneficiary"
            className={classNames('w-5 h-5 cursor-pointer', { checked: selectedSupporter?.wCCNIsBeneficiary })}
            checked={selectedSupporter?.wCCNIsBeneficiary ? true : false}
            onChange={e => {
              editSupporterClick({ wCCNIsBeneficiary: e.target.checked });
            }}
          />
          <Label htmlFor="wCCNIsBeneficiary" className="cursor-pointer">
            {t('WCCNIsBeneficiary')}
          </Label>
        </div>
      </div>
    </div>
  );
};

export default SupporterInfo;
