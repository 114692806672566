import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AddPaymentType, createPayment, updatePaymentById } from 'api/loanFundManager/paymentApi';
import GroupButton from 'components/button/groupButton';
import CustomDatePickerForm from 'components/form/dateSelectForm/customDatePickerForm';
import CustomSelectForm from 'components/form/selectForm/customSelectForm';
import CurrencyForm from 'components/form/textForm/currencyForm';
import { Modal } from 'flowbite-react';
import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { FORMAT_DATE_API, FORMAT_DATE_SHOW_TABLE, PAYMENT_STATUS_OPTIONS, ROUTE_PATHS } from 'utils/constants';
import { LoanType, OptionType, PaymentType } from 'utils/proptypes';
import { getNumberValueFromApi, getNumberValueSendToApi, messageErrors, selectDateHandler, selectItemHandler, setCurrencyValue } from 'utils/utils';
import * as Yup from 'yup';

import './addPaymentModal.scss';
import '../../../styles/common/multiSelect.scss';

type AddPaymentModalTypes = {
  openModal: boolean;
  setOpenModal: Function;
  isEdit?: boolean;
  setIsEdit?: Function;
  selectedPayment?: PaymentType;
  selectedLoan?: LoanType;
  refetchApi: Function;
  fetchLoanById?: Function;
  reLinkUrl?: string;
  paymentsSchedule?: PaymentType[];
};

export type addPaymentFormType = {
  loanId: string;
  name: string;
  principalAmount: number;
  interestAmount: number;
  paymentReceivedAmount: number;
  paymentDate: string;
  paymentReceived: string;
  status: OptionType[];
};

const AddPaymentModal = (props: AddPaymentModalTypes) => {
  const {
    openModal = false,
    setOpenModal,
    selectedPayment,
    refetchApi,
    fetchLoanById,
    isEdit = false,
    setIsEdit,
    selectedLoan,
    reLinkUrl,
    paymentsSchedule,
  } = props;
  const { t } = useTranslation();
  const [isResetForm, setIsResetForm] = useState(false);
  const [newPaymentReceivedDate, setNewPaymentReceivedDate] = useState<Date | null>(null);
  const navigate = useNavigate();
  const params = useParams();
  const loanId = params.id;
  const partnerId = params.partnerId;

  const ValidateSchema = Yup.object().shape({
    principalAmount: Yup.number()
      .min(-1000000000, t('paymentPage.amountMin'))
      .max(1000000000, t('paymentPage.amountMax'))
      .nullable()
      .typeError(t('paymentPage.amountTypeIsNumber')),
    interestAmount: Yup.number()
      .min(0, t('paymentPage.amountMin'))
      .max(1000000000, t('paymentPage.amountMax'))
      .nullable()
      .typeError(t('paymentPage.amountTypeIsNumber')),
    paymentReceivedAmount: Yup.number()
      .min(-1000000000, t('paymentPage.amountMin'))
      .max(1000000000, t('paymentPage.amountMax'))
      .required(t('paymentPage.requiredField'))
      .nullable()
      .typeError(t('paymentPage.amountTypeIsNumber')),
    paymentDate: Yup.date().required(t('paymentPage.requiredField')),
    paymentReceived: Yup.date().required(t('paymentPage.requiredField')),
    status: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().required(t('paymentPage.requiredField')),
        }),
      )
      .min(1, t('paymentPage.requiredField')),
  });

  const receivedPayments: PaymentType[] = useMemo(() => {
    return paymentsSchedule?.filter((payment: PaymentType) => payment.name && (payment.principalAmount || payment.interestAmount)) as PaymentType[];
  }, [paymentsSchedule]);

  const receivedPaymentQuantity: number = useMemo(() => {
    return receivedPayments.length;
  }, [receivedPayments]);

  const initPaymentsSchedule = useMemo(() => {
    return paymentsSchedule?.filter((payment: PaymentType) => !payment.name);
  }, [paymentsSchedule]);

  const payPrincipal: number = useMemo(() => {
    return getNumberValueFromApi(initPaymentsSchedule?.[receivedPaymentQuantity + 1]?.principalAmount as number);
  }, [initPaymentsSchedule, receivedPaymentQuantity]);

  const interestPayPerDate: number = useMemo(() => {
    return getNumberValueFromApi(
      (initPaymentsSchedule?.[receivedPaymentQuantity + 1]?.interestAmount as number) /
        (initPaymentsSchedule?.[receivedPaymentQuantity + 1]?.daysSinceLastTransaction as number),
    );
  }, [initPaymentsSchedule, receivedPaymentQuantity]);

  const newDaysSinceLastTransaction: number = useMemo(() => {
    return newPaymentReceivedDate
      ? moment(newPaymentReceivedDate).diff(
          moment(moment.utc(initPaymentsSchedule?.[receivedPaymentQuantity + 1]?.paymentDate).format(FORMAT_DATE_API)),
          t('days'),
        )
      : (initPaymentsSchedule?.[receivedPaymentQuantity + 1]?.daysSinceLastTransaction as number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPaymentReceivedDate, initPaymentsSchedule, receivedPaymentQuantity]);

  const payInterest: number = useMemo(() => {
    return (
      getNumberValueFromApi(initPaymentsSchedule?.[receivedPaymentQuantity + 1]?.interestAmount as number) +
      (newPaymentReceivedDate ? interestPayPerDate * newDaysSinceLastTransaction : 0)
    );
  }, [newPaymentReceivedDate, initPaymentsSchedule, receivedPaymentQuantity, interestPayPerDate, newDaysSinceLastTransaction]);

  const amountReceived: number = useMemo(() => {
    return payInterest + payPrincipal;
  }, [payInterest, payPrincipal]);

  const dueDate: Date | string = useMemo(() => {
    const initPaymentsScheduleWithoutElement0 = initPaymentsSchedule?.filter(
      (payment: PaymentType) => payment.totalToPay || payment.paymentReceivedAmount,
    );

    return initPaymentsScheduleWithoutElement0?.[receivedPaymentQuantity]?.paymentDate
      ? moment(moment.utc(initPaymentsScheduleWithoutElement0?.[receivedPaymentQuantity]?.paymentDate).format(FORMAT_DATE_SHOW_TABLE)).toDate()
      : '';
  }, [initPaymentsSchedule, receivedPaymentQuantity]);

  const initialValues: any = useMemo(() => {
    if (isEdit && selectedPayment) {
      return {
        principalAmount: selectedPayment?.principalAmount ? getNumberValueFromApi(selectedPayment?.principalAmount) : undefined,
        interestAmount: selectedPayment?.interestAmount ? getNumberValueFromApi(selectedPayment?.interestAmount) : undefined,
        paymentReceivedAmount: selectedPayment?.paymentReceivedAmount ? getNumberValueFromApi(selectedPayment?.paymentReceivedAmount) : undefined,
        paymentDate: selectedPayment?.paymentDate ? moment(selectedPayment?.paymentDate).toDate() : '',
        paymentReceived: selectedPayment?.paymentReceived ? moment(selectedPayment?.paymentReceived).toDate() : '',
        status: selectedPayment?.status ? PAYMENT_STATUS_OPTIONS.filter(item => item.value === selectedPayment?.status) : [],
      }
    }

    if (!isEdit && selectedPayment) {
      const paymentReceivedAmount = getNumberValueFromApi(selectedPayment?.principalAmount) + getNumberValueFromApi(selectedPayment?.interestAmount);
      return {
        principalAmount: selectedPayment?.principalAmount ? getNumberValueFromApi(selectedPayment?.principalAmount) : undefined,
        interestAmount: selectedPayment?.interestAmount ? getNumberValueFromApi(selectedPayment?.interestAmount) : undefined,
        paymentReceivedAmount: paymentReceivedAmount ? paymentReceivedAmount : undefined,
        paymentDate: selectedPayment?.paymentDate ? moment(selectedPayment?.paymentDate).toDate() : '',
        paymentReceived: selectedPayment?.paymentDate ? moment(selectedPayment?.paymentDate).toDate() : '',
        status: selectedPayment?.status ? PAYMENT_STATUS_OPTIONS.filter(item => item.value === selectedPayment?.status) : [],
      }
    }

    return {
      principalAmount: payPrincipal ? payPrincipal : undefined,
      interestAmount: payInterest ? payInterest : undefined,
      paymentReceivedAmount: amountReceived ? amountReceived : undefined,
      paymentDate: dueDate ? dueDate : '',
      paymentReceived: newPaymentReceivedDate ? newPaymentReceivedDate : dueDate,
      status: [],
      isResetForm,
    };
  }, [newPaymentReceivedDate, isEdit, selectedPayment, payInterest, payPrincipal, isResetForm, amountReceived, dueDate]);

  const handleAddAndUpdatePayment = async (paymentPayload: AddPaymentType) => {
    if (isEdit) {
      await updatePaymentById(selectedPayment?.id as string, paymentPayload);
    } else {
      await createPayment(paymentPayload);
    }
  };

  const mutation = useMutation('create-update-payment', {
    mutationFn: handleAddAndUpdatePayment,
  });

  const handleSubmit = (
    data: addPaymentFormType,
    action: {
      [key: string]: any;
    },
  ) => {
    const successCallback = (message: string) => {
      setOpenModal(!openModal);
      if (setIsEdit) setIsEdit(false);
      toast.success(message);
      action.resetForm();
      refetchApi();
      if (typeof fetchLoanById === 'function') {
        fetchLoanById();
        reLinkUrl
          ? navigate(reLinkUrl, { replace: true })
          : navigate(`${ROUTE_PATHS.PARTNERS_PAGE}/${partnerId}${ROUTE_PATHS.LOAN_PAGE}/${loanId}`, { replace: true });
      }
      setNewPaymentReceivedDate(null);
    };
    const tempData = {
      loanId: loanId as string,
      name: isEdit ? (selectedPayment?.name as string) : `WCCN-${(selectedLoan?.payments?.length as number) + 1}`,
      principalAmount: getNumberValueSendToApi(data.principalAmount),
      interestAmount: getNumberValueSendToApi(data.interestAmount),
      paymentReceivedAmount: getNumberValueSendToApi(data.paymentReceivedAmount),
      paymentDate: moment(data.paymentDate).format(FORMAT_DATE_API),
      paymentReceived: moment(data.paymentReceived).format(FORMAT_DATE_API),
      status: data.status[0].value as string,
    };

    if (openModal) {
      mutation.mutate(
        {
          ...tempData,
        },
        {
          onSuccess: () => {
            const message: string = t(isEdit ? 'paymentPage.editSuccessMessage' : 'paymentPage.createSuccessMessage');
            successCallback(message);
          },
          onError: async (error: any) => {
            const message: string = messageErrors(error, t);
            toast.error(message);
          },
        },
      );
    }
  };

  const closeModalHandler = (props: FormikProps<addPaymentFormType>) => {
    setOpenModal(!openModal);
    setNewPaymentReceivedDate(null);
    if (setIsEdit) setIsEdit(false);
    props.resetForm();
    props.setErrors({});
    if (reLinkUrl) {
      navigate(reLinkUrl, { replace: true });
    } else {
      navigate(`${ROUTE_PATHS.PARTNERS_PAGE}/${partnerId}${ROUTE_PATHS.LOAN_PAGE}/${loanId}`, { replace: true });
    }
  };

  useEffect(() => {
    if (openModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [openModal]);

  return (
    <Modal
      id="add-payment-modal"
      show={openModal}
      size="lg"
      onClose={() => {
        setOpenModal(!openModal);
        setNewPaymentReceivedDate(null);
        if (setIsEdit) setIsEdit(false);
        if (reLinkUrl) {
          navigate(reLinkUrl, { replace: true });
        } else {
          navigate(`${ROUTE_PATHS.PARTNERS_PAGE}/${partnerId}${ROUTE_PATHS.LOAN_PAGE}/${loanId}`, { replace: true });
        }
        setIsResetForm(!isResetForm);
      }}
      dismissible={true}
    >
      <Modal.Header>{!isEdit ? t('addPayment') : t('editPayment')}</Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={ValidateSchema}
          validateOnChange={true}
          className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
        >
          {(props: FormikProps<any>) => {
            return (
              <Form className="flex flex-col gap-4">
                <div className="space-y-4 px-4 pb-2 sm:pb-6 lg:px-2 xl:pb-8">
                  <div className="mb-4">{loanId}</div>
                  <CurrencyForm
                    id="principalAmount"
                    name="principalAmount"
                    label={t('principalOwed')}
                    placeholder={t('principalOwed')}
                    propsFormik={props}
                    setCurrentValue={async (
                      currency: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<addPaymentFormType>,
                    ) => {
                      await setCurrencyValue<addPaymentFormType>({ currency, props });
                    }}
                    isEdit={isEdit}
                    isRequired={true}
                    isDisabled={true}
                    unit="$"
                    styleUnit="prefix"
                    decimalLimit={2}
                  />
                  <CurrencyForm
                    id="interestAmount"
                    name="interestAmount"
                    label={t('interestOwed')}
                    placeholder={t('interestOwed')}
                    propsFormik={props}
                    setCurrentValue={async (
                      currency: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<addPaymentFormType>,
                    ) => {
                      await setCurrencyValue<addPaymentFormType>({ currency, props });
                    }}
                    isEdit={isEdit}
                    isRequired={true}
                    isDisabled={true}
                    unit="$"
                    styleUnit="prefix"
                    decimalLimit={2}
                  />
                  <CurrencyForm
                    id="paymentReceivedAmount"
                    name="paymentReceivedAmount"
                    label={t('amountReceived')}
                    placeholder={t('amountReceived')}
                    propsFormik={props}
                    setCurrentValue={async (
                      currency: {
                        name?: string;
                        value?: string;
                      },
                      props: FormikProps<addPaymentFormType>,
                    ) => {
                      await setCurrencyValue<addPaymentFormType>({ currency, props });
                    }}
                    isEdit={isEdit}
                    isRequired={true}
                    unit="$"
                    styleUnit="prefix"
                    decimalLimit={2}
                  />
                  <CustomDatePickerForm
                    id="paymentDate"
                    name="paymentDate"
                    label={t('dueDate')}
                    placeHolder={t('dueDate')}
                    propsFormik={props}
                    selectItemsHandler={(date: Date) =>
                      selectDateHandler<addPaymentFormType>({
                        date,
                        props,
                        fieldName: 'paymentDate',
                      })
                    }
                    isEdit={isEdit}
                    isDisabled={true}
                    isDeleteSelectedDate={openModal}
                  />
                  <CustomDatePickerForm
                    id="paymentReceived"
                    name="paymentReceived"
                    label={t('paymentReceived')}
                    placeHolder={t('paymentReceived')}
                    propsFormik={props}
                    selectItemsHandler={async (date: Date) => {
                      await selectDateHandler<addPaymentFormType>({
                        date,
                        props,
                        fieldName: 'paymentReceived',
                      });
                    }}
                    isEdit={isEdit}
                    isRequired={true}
                    isDeleteSelectedDate={openModal}
                    dataTestId="paymentReceived"
                  />
                  <CustomSelectForm
                    label={t('status')}
                    id="status"
                    name="status"
                    placeHolder={t('status')}
                    options={PAYMENT_STATUS_OPTIONS}
                    selectItemsHandler={async (items: any, props: FormikProps<addPaymentFormType>) => {
                      await selectItemHandler<addPaymentFormType>({ items, props, fieldName: 'status' });
                    }}
                    propsFormik={props}
                    isRequired={true}
                    isEdit={isEdit}
                    isMulti={false}
                    disableSearch={true}
                  />
                </div>
                <GroupButton
                  className="w-full gap-2 justify-center pb-2"
                  buttons={[
                    {
                      type: 'button',
                      text: t('modal.cancel'),
                      classType: 'white',
                      action: () => {
                        closeModalHandler(props);
                      },
                    },
                    {
                      type:
                        receivedPaymentQuantity + 1 >= (paymentsSchedule?.filter((payment: PaymentType) => !payment.name)?.length as number)
                          ? 'button'
                          : 'submit',
                      text: t('modal.save'),
                      classType: 'blue',
                      action: () => {
                        if (receivedPaymentQuantity + 1 >= (paymentsSchedule?.filter((payment: PaymentType) => !payment.name)?.length as number)) {
                          const message = t('loanHasBeenPaidOff');
                          toast.warn(message);
                          closeModalHandler(props);
                        }
                      },
                    },
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddPaymentModal;
