import { MutableRefObject, useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus, HiX } from 'react-icons/hi';
import classNames from 'classnames';
import NoResult from 'components/common/noResult';
import TooltipComponent from 'components/common/Tooltip';
import DateInput from 'components/input/dateInput/DateInput';
import { AbilityContext } from 'contexts/can';
import { Badge } from 'flowbite-react';
import moment from 'moment';
import {
  ABILITY_ACTION,
  FORMAT_DATE_API,
  FORMAT_DATE_PICKER,
  FORMAT_DATE_SHOW_TABLE,
  PAYMENT_STATUS,
  PAYMENT_STATUS_OPTIONS,
  PERMISSION,
} from 'utils/constants';
import { PaymentType } from 'utils/proptypes';
import { convertNumberToCurrency, getColorStatus, getNumberValueFromApi } from 'utils/utils';

import CustomSpinner from '../../../../../../components/common/customSpinner';
import CurrencyInput from '../../../../../../components/input/currencyInput/CurrencyInput';
import CustomSelectInput from '../../../../../../components/input/selectInput/CustomSelectInput';

type RenderPaymentsTableScheduleDataType = {
  paymentsSchedule: PaymentType[];
  editPaymentHandler: Function;
  editPaymentScheduleHandler: Function;
  isFetching: boolean;
  tbl: MutableRefObject<null>;
  totalDisplay?: {
    totalDaysSinceLastTransaction: number;
    totalInterestAmount: number;
    totalOfTotalToPay: number;
    totalPrincipalAmount: number;
  };
  setSelectedPayment: Function;
  setIsOpenRemoveModal: Function;
  addPayment: Function;
  isOpenRemoveModal: boolean;
};

const RenderPaymentsTableScheduleData = (props: RenderPaymentsTableScheduleDataType) => {
  const ability = useContext(AbilityContext);
  const [t] = useTranslation();
  const inputRef = useRef();

  const {
    paymentsSchedule = [],
    isFetching = false,
    tbl,
    editPaymentHandler,
    editPaymentScheduleHandler,
    setIsOpenRemoveModal,
    isOpenRemoveModal,
    setSelectedPayment,
    addPayment,
  } = props;

  const receivedPayments: PaymentType[] = useMemo(() => paymentsSchedule?.filter(item => !!item.status), [paymentsSchedule]);

  return (
    <table className={classNames('w-full text-sm text-left text-gray-500 dark:text-gray-400', { 'h-full': !paymentsSchedule.length })} ref={tbl}>
      <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400 rounded">
        <tr>
          <th scope="col" className="py-3 px-4 max-width-108 payment--truncate">
            {t('paymentDate')}
          </th>
          <th scope="col" className="py-3 px-4 max-width-108 payment--truncate hidden-mobile-tablet">
            {t('principalToPay')}
          </th>
          <th scope="col" className="py-3 px-4 max-width-108 payment--truncate hidden-mobile">
            {t('daysSinceLastTrans')}
          </th>
          <th scope="col" className="py-3 px-4 max-width-108 payment--truncate hidden-mobile-tablet">
            {t('interestToPay')}
          </th>
          <th scope="col" className="py-3 px-4 max-width-108 payment--truncate hidden-mobile-tablet">
            {t('totalToPay')}
          </th>
          <th scope="col" className="py-3 px-4 max-width-108 payment--truncate hidden-mobile">
            {t('balance')}
          </th>
          <th scope="col" className="py-3 px-3 max-width-80 hidden-mobile" />
        </tr>
      </thead>
      <tbody className="text-xs">
        {paymentsSchedule?.length > 0 ? (
          paymentsSchedule?.map((payment: PaymentType, key: number) => {
            return (
              payment.status !== PAYMENT_STATUS.UN_PAID && (
                <tr
                  key={`${key}-list-payment`}
                  className={classNames(
                    `payment-${
                      key === paymentsSchedule?.length - 1 ? 'last' : key
                    } border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600`,
                    { 'bg-gray-100': !!payment.name },
                  )}
                >
                  {payment.paymentReceived || (!payment.daysSinceLastTransaction && payment.principalAmount >= 0) ? (
                    <th scope="row" className="py-3 px-4 font-normal text-gray-900 payments--truncate dark:text-white max-width-108">
                      <TooltipComponent
                        anchorSelect={`payment-schedule-received-date-${key}`}
                        content={
                          payment.paymentReceived
                            ? `${t('received')}: ${moment.utc(payment?.paymentReceived).format(FORMAT_DATE_SHOW_TABLE)}`
                            : moment.utc(payment.paymentDate).format(FORMAT_DATE_SHOW_TABLE)
                        }
                        className="font-normal payment--truncate max-w-full dark:text-white"
                      />
                    </th>
                  ) : (
                    <th scope="row" className="py-1 px-4 font-normal text-gray-900">
                      <DateInput
                        dateFormat={FORMAT_DATE_PICKER}
                        defaultValue={payment?.paymentDate ? payment?.paymentDate : undefined}
                        selectItemsHandler={(date: Date) => {
                          editPaymentScheduleHandler({ paymentDate: date, id: payment.id });
                        }}
                        isClearable={false}
                        isDisabled={!ability.can(ABILITY_ACTION.manage, PERMISSION.PAYMENT.UPDATE)}
                        filterDate={(date: Date) => {
                          if (key + 1 < paymentsSchedule.length) {
                            return (
                              date > moment(moment.utc(paymentsSchedule?.[key - 1]?.paymentDate).format(FORMAT_DATE_API)).toDate() &&
                              date < moment(moment.utc(paymentsSchedule?.[key + 1]?.paymentDate).format(FORMAT_DATE_API)).toDate()
                            );
                          }
                          return (
                            date > moment(moment.utc(paymentsSchedule?.[key - 1]?.paymentDate).format(FORMAT_DATE_API)).toDate() &&
                            date <
                              moment(
                                moment
                                  .utc(paymentsSchedule?.[key - 1]?.paymentDate)
                                  .add(10, 'years')
                                  .format(FORMAT_DATE_API),
                              ).toDate()
                          );
                        }}
                      />
                    </th>
                  )}
                  {payment.name || (!payment.daysSinceLastTransaction && payment.principalAmount >= 0) ? (
                    <th
                      scope="row"
                      className="py-3 px-4 font-normal text-gray-900 payments--truncate dark:text-white max-width-108 hidden-mobile-tablet"
                    >
                      {payment.principalAmount && !payment?.name ? convertNumberToCurrency(getNumberValueFromApi(payment.principalAmount)) : ''}
                    </th>
                  ) : (
                    <th scope="row" className="py-1 px-4 font-normal text-gray-900 hidden-mobile-tablet">
                      <CurrencyInput
                        defaultValue={payment.principalAmount ? getNumberValueFromApi(payment.principalAmount) : undefined}
                        changeValueHandler={(principalAmount: number, isBlur: boolean) => {
                          editPaymentScheduleHandler(
                            {
                              principalAmount,
                              id: payment.id,
                            },
                            isBlur,
                          );
                        }}
                        prefix="$"
                        decimalLimit={2}
                        inputRef={inputRef}
                        isDisabled={
                          !ability.can(ABILITY_ACTION.manage, PERMISSION.PAYMENT.UPDATE) ||
                          key === paymentsSchedule.length - (paymentsSchedule[paymentsSchedule?.length - 1]?.name ? 2 : 1)
                        }
                        hasBlur={true}
                      />
                    </th>
                  )}
                  {!payment.name ? (
                    <th scope="row" className="py-3 px-4 font-normal text-gray-900 payments--truncate dark:text-white max-width-108 hidden-mobile">
                      {payment.daysSinceLastTransaction && !payment.status
                        ? `${payment.daysSinceLastTransaction} ${t('days')}`
                        : payment.status && (
                            <Badge className={`${getColorStatus(payment.status)} h-6 text-white font-normal p-2`} style={{ width: 'fit-content' }}>
                              {payment.status}
                            </Badge>
                          )}
                    </th>
                  ) : (
                    <th scope="row" className="py-3 px-4 font-normal text-gray-900 hidden-mobile">
                      <CustomSelectInput
                        defaultValue={PAYMENT_STATUS_OPTIONS.filter(status => status.value === payment.status)}
                        options={PAYMENT_STATUS_OPTIONS}
                        selectItemsHandler={(paymentStatus: string) => {
                          editPaymentHandler({ status: paymentStatus, selectedPayment: payment });
                        }}
                        disableSearch={true}
                        isDisabled={!ability.can(ABILITY_ACTION.manage, PERMISSION.PAYMENT.UPDATE)}
                      />
                    </th>
                  )}
                  <th
                    scope="row"
                    className="py-3 px-4 font-normal text-gray-900 payments--truncate dark:text-white max-width-108 hidden-mobile-tablet"
                  >
                    {payment?.interestAmount && !payment?.name ? convertNumberToCurrency(getNumberValueFromApi(payment.interestAmount)) : ''}
                  </th>
                  <th scope="row" className="py-1 px-4 font-normal text-gray-900 hidden-mobile-tablet">
                    {payment.name ? (
                      <CurrencyInput
                        defaultValue={payment.paymentReceivedAmount ? getNumberValueFromApi(payment.paymentReceivedAmount) : undefined}
                        changeValueHandler={(paymentReceivedAmount: number) => {
                          editPaymentHandler({
                            paymentReceivedAmount,
                            selectedPayment: payment,
                          });
                        }}
                        prefix="$"
                        decimalLimit={2}
                        inputRef={inputRef}
                        isDisabled={!ability.can(ABILITY_ACTION.manage, PERMISSION.PAYMENT.UPDATE)}
                      />
                    ) : payment.totalToPay || payment.paymentReceivedAmount ? (
                      convertNumberToCurrency(
                        getNumberValueFromApi(
                          payment.paymentReceivedAmount ? (payment.paymentReceivedAmount as number) : (payment.totalToPay as number),
                        ),
                      )
                    ) : (
                      ''
                    )}
                  </th>
                  <th scope="row" className="py-3 px-4 font-normal text-gray-900 payments--truncate dark:text-white max-width-108 hidden-mobile">
                    {payment.balance && !payment?.name ? convertNumberToCurrency(getNumberValueFromApi(payment.balance)) : ''}
                  </th>
                  <th scope="row" className="py-3 px-4 font-normal text-gray-900 payments--truncate dark:text-white max-width-108 hidden-mobile">
                    {ability &&
                      ability.can(ABILITY_ACTION.manage, PERMISSION.PAYMENT.DELETE) &&
                      key > 0 &&
                      key !== paymentsSchedule?.length - (paymentsSchedule[paymentsSchedule?.length - 1]?.name ? 2 : 1) && (
                        <button
                          onClick={() => {
                            setIsOpenRemoveModal(!isOpenRemoveModal);
                            setSelectedPayment(payment);
                          }}
                          className="delete-loan-payment-button cursor-pointer px-1 py-1 text-white bg-red-700 rounded-lg float-right"
                        >
                          <HiX className="h-4 w-4" />
                        </button>
                      )}
                    {key > 0 && !payment?.paymentReceived && !paymentsSchedule[key + 1]?.name && <button
                      onClick={() => {
                        addPayment(payment);
                        setSelectedPayment(payment);
                      }}
                      className="add-loan-payment-button cursor-pointer px-1 py-1 mr-2 text-white bg-blue-700 rounded-lg float-right right-0"

                    >
                      <HiPlus className="h-4 w-4" />
                    </button>}
                  </th>
                </tr>
              )
            );
          })
        ) : (
          <tr className="h-full payment-no-result text-center bg-white">
            <th colSpan={8}>{isFetching ? <CustomSpinner /> : <NoResult />}</th>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default RenderPaymentsTableScheduleData;
