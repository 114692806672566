import { MutableRefObject, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import NoResult from 'components/common/noResult';
import TooltipComponent from 'components/common/Tooltip';
import CurrencyInput from 'components/input/currencyInput/CurrencyInput';
import DateInput from 'components/input/dateInput/DateInput';
import CustomSelectInput from 'components/input/selectInput/CustomSelectInput';
import { AbilityContext } from 'contexts/can';
import moment from 'moment';
import {
  ABILITY_ACTION,
  FORMAT_DATE_API,
  FORMAT_DATE_API_REGEX,
  FORMAT_DATE_PICKER,
  FORMAT_DATE_SHOW_TABLE,
  PAYMENT_STATUS_OPTIONS,
  PERMISSION,
} from 'utils/constants';
import { PaymentType } from 'utils/proptypes';
import { convertNumberToCurrency, getNumberValueFromApi } from 'utils/utils';

import CustomSpinner from '../../../../../components/common/customSpinner';

const updateNoteIcon = require('../../../../../assets/image/svg/payments/note-icon.svg').default;

type RenderPaymentsRecordTableDataType = {
  tbl: MutableRefObject<null>;
  payments: PaymentType[];
  editPaymentHandler: Function;
  updateNotesPaymentHandler: Function;
  dangerouslySetInnerHTML: any;
  isFetching: boolean;
};

const RenderPaymentsTable = (props: RenderPaymentsRecordTableDataType) => {
  const ability = useContext(AbilityContext);
  const { tbl, payments, editPaymentHandler, updateNotesPaymentHandler, isFetching } = props;
  const inputRef = useRef();
  const [t] = useTranslation();
  return (
    <table className={classNames('w-full text-sm text-left text-gray-500', { 'h-full': !payments.length })} ref={tbl}>
      <thead className="text-xs text-gray-700 uppercase bg-gray-100">
        <tr>
          <th scope="col" className="py-1 px-4">
            <TooltipComponent anchorSelect="payment-trans-id-title" content={t('transID')} className="loan-payments--truncate max-w-full" />
          </th>
          <th scope="col" className="py-1 px-4">
            <TooltipComponent anchorSelect="payment-loan-name-title" content={t('loan')} className="loan-payments--truncate max-w-full" />
          </th>
          <th scope="col" className="py-1 px-4">
            <TooltipComponent anchorSelect="payment-partner-name-title" content={t('partner')} className="loan-payments--truncate max-w-full" />
          </th>
          <th scope="col" className="py-1 px-4">
            <TooltipComponent anchorSelect="payment-principal-due-title" content={t('principalDue')} className="loan-payments--truncate max-w-full" />
          </th>
          <th scope="col" className="py-1 px-4">
            <TooltipComponent anchorSelect="payment-interest-due-title" content={t('interestDue')} className="loan-payments--truncate max-w-full" />
          </th>
          <th scope="col" className="py-1 px-4">
            <TooltipComponent anchorSelect="payment-payment-date-title" content={t('accrualDate')} className="loan-payments--truncate max-w-full" />
          </th>
          <th scope="col" className="py-1 px-4">
            <TooltipComponent anchorSelect="payment-received-date-title" content={t('receivedDate')} className="loan-payments--truncate" />
          </th>
          <th scope="col" className="py-1 px-4">
            <TooltipComponent anchorSelect="amount-received-title" content={t('amountReceived')} className="loan-payments--truncate" />
          </th>
          <th scope="col" className="py-1 px-4">
            <TooltipComponent anchorSelect="payment-status-title" content={t('status')} className="loan-payments--truncate" />
          </th>
          <th scope="col" className="py-3 pr-6 w-10">
            <p className="sr-only">{t('addNotes')}</p>
          </th>
        </tr>
      </thead>
      <tbody>
        {payments?.length ? (
          payments?.map((item: PaymentType, key: number) => {
            return (
              <tr
                key={`${key}-list-payment`}
                className={`payment-${key === payments?.length - 1 ? 'last' : key} relative bg-white border-b hover:bg-gray-50`}
              >
                <th scope="row" className="py-1 px-4 font-normal text-gray-900 ">
                  <TooltipComponent
                    anchorSelect={`payment-name-${key}`}
                    content={item?.name ? item?.name : ''}
                    className="loan-payments--truncate max-w-full"
                  />
                </th>
                <th scope="row" className="py-1 px-4 font-normal text-gray-900">
                  <TooltipComponent
                    anchorSelect={`loan-name-${key}`}
                    content={item?.loan?.name ? item?.loan?.name : ''}
                    className="font-normal loan-payments--truncate max-w-full"
                  />
                </th>
                <th scope="row" className="py-1 px-4 font-normal text-gray-900">
                  <TooltipComponent
                    anchorSelect={`partner-name-${key}`}
                    content={item?.loan?.partner?.name ? item?.loan?.partner?.name : ''}
                    className="font-normal loan-payments--truncate max-w-full"
                  />
                </th>
                <th scope="row" className="py-1 px-4 font-normal text-gray-900">
                  <TooltipComponent
                    anchorSelect={`payment-principal-amount-${key}`}
                    content={item?.principalAmount ? convertNumberToCurrency(getNumberValueFromApi(item?.principalAmount)) : ''}
                    className="font-normal loan-payments--truncate max-w-full"
                  />
                </th>
                <th scope="row" className="py-1 px-4 font-normal text-gray-900">
                  <TooltipComponent
                    anchorSelect={`payment-interest-amount-${key}`}
                    content={item?.interestAmount ? convertNumberToCurrency(getNumberValueFromApi(item?.interestAmount)) : ''}
                    className="font-normal loan-payments--truncate max-w-full"
                  />
                </th>
                <th scope="row" className="py-1 px-4 font-normal text-gray-900">
                  <TooltipComponent
                    anchorSelect={`payment-due-date-amount-${key}`}
                    content={
                      item?.paymentDate && FORMAT_DATE_API_REGEX.test(moment(item?.paymentDate).format(FORMAT_DATE_API))
                        ? moment.utc(item?.paymentDate).format(FORMAT_DATE_SHOW_TABLE)
                        : ''
                    }
                    className="font-normal loan-payments--truncate max-w-full"
                  />
                </th>
                <th scope="row" className="py-1 px-4 font-normal text-gray-900">
                  <DateInput
                    dateFormat={FORMAT_DATE_PICKER}
                    defaultValue={item?.paymentReceived ? item?.paymentReceived : undefined}
                    selectItemsHandler={(date: Date) => {
                      editPaymentHandler({ paymentReceived: date, selectedPayment: item });
                    }}
                    isClearable={false}
                    isDisabled={!ability.can(ABILITY_ACTION.manage, PERMISSION.PAYMENT.UPDATE)}
                  />
                </th>
                <th scope="row" className="py-1 px-4 font-normal text-gray-900">
                  <CurrencyInput
                    defaultValue={item?.paymentReceivedAmount ? getNumberValueFromApi(item?.paymentReceivedAmount) : undefined}
                    changeValueHandler={(paymentReceivedAmount: number) => {
                      editPaymentHandler({ paymentReceivedAmount, selectedPayment: item });
                    }}
                    prefix="$"
                    decimalLimit={2}
                    inputRef={inputRef}
                    isDisabled={!ability.can(ABILITY_ACTION.manage, PERMISSION.PAYMENT.UPDATE)}
                  />
                </th>
                <th scope="row" className="py-1 px-4 font-normal text-gray-900 loan-payments--truncate">
                  <CustomSelectInput
                    defaultValue={PAYMENT_STATUS_OPTIONS.filter(status => status.value === item.status)}
                    options={PAYMENT_STATUS_OPTIONS}
                    selectItemsHandler={(paymentStatus: string) => {
                      editPaymentHandler({ status: paymentStatus, selectedPayment: item });
                    }}
                    disableSearch={true}
                    isDisabled={!ability.can(ABILITY_ACTION.manage, PERMISSION.PAYMENT.UPDATE)}
                  />
                </th>
                <th scope="row" className="py-1 px-4">
                  {ability.can(ABILITY_ACTION.manage, PERMISSION.PAYMENT.UPDATE) && (
                    <img
                      src={updateNoteIcon}
                      className="cursor-pointer h-5 w-5 max-w-xl"
                      onClick={() => updateNotesPaymentHandler(item)}
                      alt="note icon"
                    />
                  )}
                </th>
              </tr>
            );
          })
        ) : (
          <tr className="h-full payment-no-result text-center">
            <th colSpan={10}>{isFetching ? <CustomSpinner /> : <NoResult />}</th>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default RenderPaymentsTable;
