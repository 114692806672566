import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiArrowNarrowLeft, HiOutlineStar, HiPencilAlt, HiPlus } from 'react-icons/hi';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import { getLoansByPartnerId } from 'api/loanFundManager/loanApi';
import { getPartnerById } from 'api/loanFundManager/partnerApi';
import ButtonIconWithText from 'components/button/buttonIconWithText';
import CardIndexGroup from 'components/common/CardIndexGroup';
import AddPartnerModal from 'components/modal/partner/addPartnerModal';
import EditRatingPartnerModal from 'components/modal/partner/editRatingPartnerModal';
import { AbilityContext } from 'contexts/can';
import useLoading from 'hooks/useLoading';
import moment from 'moment/moment';
import LoansPage from 'pages/loanFundManager/loans/loans';
import { loanAction } from 'redux/actions';
import { loanSelector } from 'redux/selectors';
import { ABILITY_ACTION, DEFAULT_VALUE_FILTER, FORMAT_DATE_SHOW_TABLE, PERMISSION, ROUTE_PATHS } from 'utils/constants';
import { ContactType, PartnerType } from 'utils/proptypes';
import { getNumberValueFromApi, mapToLetterGrade, renderLetterGradeByExternalRating } from 'utils/utils';

import AddNotesPartnerModal from '../../../../components/modal/partner/addNotesPartnerModal';
import AddPartnerContactsModal from '../../../../components/modal/partner/addPartnerContactsModal';

import PartnerInfo from './components/PartnerInfo';
import PartnerNote from './components/PartnerNote';
import VectorIcon from './components/VectorIcon';

import './renderPartnerDetail.scss';

const RenderPartnerDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef(null);
  const params = useParams();
  const ability = useContext(AbilityContext);
  const partnerId = params.id;

  const {
    loansOfPartner: { currentPage },
  } = useSelector(loanSelector);

  const [t] = useTranslation()
  const [openEditPartnerModal, setOpenEditPartnerModal] = useState(false);
  const [openEditRatingPartnerModal, setOpenEditRatingPartnerModal] = useState(false);
  const [openAddPartnerContactsModal, setOpenAddPartnerContactsModal] = useState(false);
  const [openAddPartnerNotesModal, setOpenAddPartnerNotesModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<PartnerType>();
  const [selectedContact, setSelectedContact] = useState<ContactType>();
  const [isEditContact, setIsEditContact] = useState(false);

  const { refetch: fetchPartnerById, isFetching } = useQuery(['getPartner', partnerId], () => getPartnerById(partnerId as string), {
    onSuccess: ({ data }) => {
      setSelectedPartner(data);
    },
    onError: () => {
      setSelectedPartner(undefined);
    },
  });

  useLoading({ isLoading: isFetching, ref });

  const editPartnerHandler = () => {
    setOpenEditPartnerModal(!openEditPartnerModal);
    setIsEdit(!isEdit);
  };

  const editRatingPartner = () => {
    setOpenEditRatingPartnerModal(!openEditRatingPartnerModal);
  };

  const addPartnerNotes = () => {
    setOpenAddPartnerNotesModal(!openAddPartnerNotesModal);
  };

  const { refetch: fetchLoansByPartnerId } = useQuery(
    ['loans'],
    () => getLoansByPartnerId({ page: currentPage ?? 0, limit: DEFAULT_VALUE_FILTER.LIMIT, partnerId }),
    {
      onSuccess: ({ data }) => {
        dispatch(
          loanAction.setLoansOfPartner({
            ...data,
            entities: data.entities,
            totalEntities: data.totalEntities,
            rowPerPages: data.entities.length,
            currentPage,
          }),
        );
      },
      onError: () => {
        dispatch(loanAction.setLoansOfPartner(undefined));
      },
      enabled: false,
    },
  );

  const cardIndexData = [
    {
      bgColor: 'bg-blue-50',
      bgIconColor: 'bg-blue-500',
      textIconColor: 'text-white',
      label: t('Business Sector'),
      value: selectedPartner?.businessSector ? selectedPartner?.businessSector : '',
      icon: HiOutlineStar,
    },
    {
      bgColor: 'bg-red-50',
      bgIconColor: 'bg-red-500',
      textIconColor: 'text-white',
      label: t('Credit Limit'),
      value: selectedPartner?.creditLimit
        ? getNumberValueFromApi(selectedPartner?.creditLimit)
        : '',
      icon: HiOutlineStar,
    },
    {
      bgColor: 'bg-gray-100',
      bgIconColor: 'bg-gray-500',
      textIconColor: 'text-white',
      label: t('Loan Level Reserve'),
      value: selectedPartner?.llr ? `${getNumberValueFromApi(selectedPartner?.llr)}%` : '',
      icon: VectorIcon,
    },
    {
      bgColor: 'bg-teal-50',
      bgIconColor: 'bg-teal-500',
      textIconColor: 'text-white',
      label: t('Board Approved Credit Limit'),
      value: selectedPartner?.boardApprovedCreditLimit ? getNumberValueFromApi(selectedPartner?.boardApprovedCreditLimit) : '',
      icon: HiOutlineStar,
    },
    {
      bgColor: 'bg-pink-50',
      bgIconColor: 'bg-pink-500',
      textIconColor: 'text-white',
      label: t('Credit Limit Expiration'),
      value: selectedPartner?.creditLimitExpirationDate
        ? moment.utc(selectedPartner?.creditLimitExpirationDate).format(FORMAT_DATE_SHOW_TABLE)
        : '',
      icon: HiOutlineStar,
    },
    {
      bgColor: 'bg-orange-50',
      bgIconColor: 'bg-orange-500',
      textIconColor: 'text-white',
      label: t('Fund Allocation'),
      value: selectedPartner?.fundAllocation ? selectedPartner?.fundAllocation : '',
      icon: VectorIcon,
    },
    {
      bgColor: 'bg-yellow-50',
      bgIconColor: 'bg-yellow-300',
      textIconColor: 'text-white',
      label: t('internalRating'),
      value: selectedPartner?.internalRating
        ? `${Object.values(mapToLetterGrade(getNumberValueFromApi(selectedPartner?.internalRating))).join(' - ')}`
        : '',
      subValue: selectedPartner?.internalRatingDate ? moment.utc(selectedPartner?.internalRatingDate).format(FORMAT_DATE_SHOW_TABLE) : '',
      icon: HiOutlineStar,
    },
    {
      bgColor: 'bg-green-100',
      bgIconColor: 'bg-green-400',
      textIconColor: 'text-white',
      label: t('externalRating'),
      value: selectedPartner?.externalRating
        ? `${getNumberValueFromApi(selectedPartner?.externalRating)} - ${renderLetterGradeByExternalRating(
          getNumberValueFromApi(selectedPartner?.externalRating),
        )} `
        : '',
      subValue: selectedPartner?.externalRatingDate ? moment.utc(selectedPartner?.externalRatingDate).format(FORMAT_DATE_SHOW_TABLE) : '',
      icon: HiOutlineStar,
    },
    {
      bgColor: 'bg-purple-100',
      bgIconColor: 'bg-purple-500',
      textIconColor: 'text-white',
      label: t('outlook'),
      value: selectedPartner?.outlook ? selectedPartner?.outlook : '',
      icon: VectorIcon,
    },
  ];

  const handleGoBack = useCallback(() => {
    dispatch(loanAction.setLoansOfPartner(undefined));
  }, [dispatch]);

  useEffect(() => {
    const handlePopstate = (event: any) => {
      if (event.state !== null) {
        handleGoBack();
      }
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      if (!typeof window) {
        window.removeEventListener('popstate', handlePopstate);
      }
    };
  }, [handleGoBack]);

  return (
    <>
      <LoadingBar color="#a1c93a" ref={ref} shadow={true} containerStyle={{ height: '3px' }} />
      <div className="partner-detail-page flex flex-col justify-start items-center">
        <div className="header w-full flex justify-between items-center px-4 py-4">
          <HiArrowNarrowLeft
            className="header__icon leading-none text-gray-900 text-base font-medium cursor-pointer border border-gray-900 rounded-full p-1"
            size={36}
            onClick={() => {
              navigate(ROUTE_PATHS.PARTNERS_PAGE);
              dispatch(loanAction.setLoansOfPartner(undefined));
            }}
          />
          <h3 className="header__title leading-none text-gray-900 text-base font-medium">{t('partnerInformation')}</h3>
          {ability.can(ABILITY_ACTION.manage, PERMISSION.PARTNER.UPDATE) ? (
            <div className="flex space-x-4">
              <ButtonIconWithText className="header__button bg-blue-900" text={t('updateRating')} onClick={() => editRatingPartner()} Icon={HiPlus} />
              <ButtonIconWithText
                className="header__button bg-blue-900"
                text={t('editPartner')}
                onClick={() => editPartnerHandler()}
                Icon={HiPencilAlt}
              />
            </div>
          ) : (
            <div className="header__button" />
          )}
        </div>
        <div className="partner-detail h-full px-4 pb-4 flex justify-center items-center space-x-4">
          <div className="partner-detail__left h-full w-full space-y-4">
            <div className="partner-detail__left--info rounded">
              <PartnerInfo selectedPartner={selectedPartner as PartnerType} setOpenAddPartnerContactsModal={setOpenAddPartnerContactsModal} ability={ability} setSelectedContact={setSelectedContact} setIsEditContact={setIsEditContact} />
            </div>
            <div className="partner-detail__left--note rounded">
              <PartnerNote notes={selectedPartner?.notes ?? []} addPartnerNotes={addPartnerNotes} ability={ability} />
            </div>
          </div>
          <div className="partner-detail__right h-full">
            <CardIndexGroup cardIndexData={cardIndexData} className="grid grid-cols-5 gap-4 mb-3" />
            <LoansPage fetchPartnerById={fetchPartnerById} ability={ability} isFetchingPartner={isFetching} selectedPartner={selectedPartner} />
          </div>
        </div>
      </div>
      <AddPartnerModal
        openModal={openEditPartnerModal}
        setOpenModal={setOpenEditPartnerModal}
        refetchApi={fetchPartnerById}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        selectedPartner={selectedPartner}
        isComeback={false}
        fetchLoansByPartnerId={fetchLoansByPartnerId}
      />
      <EditRatingPartnerModal
        openModal={openEditRatingPartnerModal}
        setOpenModal={setOpenEditRatingPartnerModal}
        refetchApi={fetchPartnerById}
        selectedPartner={selectedPartner as PartnerType}
        isComeback={false}
        fetchLoansByPartnerId={fetchLoansByPartnerId}
      />
      <AddPartnerContactsModal openModal={openAddPartnerContactsModal} setOpenModal={setOpenAddPartnerContactsModal} selectedContact={selectedContact} isEditContact={isEditContact} />
      <AddNotesPartnerModal
        openModal={openAddPartnerNotesModal}
        // notes={notesStore?.data?.entities}
        notes={selectedPartner?.notes ?? []}
        setOpenModal={setOpenAddPartnerNotesModal}
        selectedPartner={selectedPartner}
      />
    </>
  );
};

export default RenderPartnerDetail;
